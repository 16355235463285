import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import locales from "../../constants";

const VeganCola = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";

  return (
    <Layout
      seo={{
        title: "Vegan Cola",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/vegan-cola_og_image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #66d43f, #59bf35, #50a831, #4a9c2d, #449029 )",
        icons: "#4a9c2d",
        navClass: "vegan-cola__nav",
        ogImage: require("../../assets/img/portfolio/vegan-cola_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/vegan-cola/",
      }}
    >
      <PortfolioHeader name="vegan-cola" height="350" />
      <section
        className="portfolio-intro vegan-cola-intro vegan-cola-section vegan-cola-section__intro"
        id="info"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Vegan Cola</h1>
                <ul>
                  <li>Refresh loga</li>
                  <li>Refresh opakowania</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  Dla firmy Vitamizu odświeżyliśmy projekt logo oraz oraz
                  opakowań flagowego produktu "Vegan Cola", napoju gazowanego ze
                  stewią o smaku coli. Napój ten nie jest testowany na
                  zwierzętach w przeciwieństwie do zwykłej coli, nie zawiera
                  konserwantów i jest wolny od glutenu.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vegan-cola-section vegan-cola-section__banner">
        <img
          className="can img-fluid"
          src={require("../../assets/img/portfolio/vegan-cola_can.png")}
          alt="Puszka Vegan Cola"
          loading="lazy"
        />
      </section>

      <section className="vegan-cola-section vegan-cola-section__logo">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 description">
              <h2>Refresh loga</h2>
              <p>
                Naszym zadaniem było lekkie i zgrabne odświeżenie logo produktu,
                tak aby odpowiadało obecnym trendom rynkowym.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img
                className="sketch"
                src={require("../../assets/img/portfolio/vegan-cola_sketch.jpg")}
                alt="Szkic loga Vegan Cola"
                loading="lazy"
              />
            </div>
          </div>
          <div className="row paper">
            <div className="col-12 col-md-6">
              <div className="logo-wrapper">
                <img
                  src={require("../../assets/img/portfolio/vegan-cola_logo_old.svg")}
                  alt="Logo przed odświeżeniem"
                  loading="lazy"
                />
              </div>
              <p>Logo przed oświeżeniem</p>
            </div>
            <div className="col-12 col-md-6">
              <img
                src={require("../../assets/img/portfolio/vegan-cola_logo_new.svg")}
                alt="Logo po odświeżeniu"
                loading="lazy"
              />
              <p>Logo po refreshu</p>
            </div>
          </div>
        </div>
      </section>

      <section className="vegan-cola-section vegan-cola-section__package">
        <div className="container-fluid">
          <div className="row align-items-center vegan-cola-section__package--cans">
            <div className="col-12 col-md-8">
              <img
                className="cans"
                src={require("../../assets/img/portfolio/vegan-cola_cans.jpg")}
                alt="Dwie puszki Vegan Cola"
              />
            </div>
            <div className="col-12 col-md-4 description">
              <div className="inner">
                <h2>Refresh opakowania</h2>
                <p>
                  Nowe opakowanie zostało zaprojektowane przy użyciu
                  intensywnych barw zieleni, tak aby odzwierciedlić zastrzyk
                  energii płynący z napoju oraz podkreślić jego pro
                  ekologiczność.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center vegan-cola-section__package--labels">
            <div className="col-12 col-md-10 order-1">
              <img
                src={require("../../assets/img/portfolio/vegan-cola_label_old.jpg")}
                alt="Stara etykieta Vegan Cola"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-md-2 order-2">
              <p>Projekt bazowy</p>
            </div>
            <div className="col-12 col-md-2 order-4 order-md-3">
              <p>Projekt po odświeżeniu</p>
            </div>
            <div className="col-12 col-md-10 order-3 order-md-4">
              <img
                src={require("../../assets/img/portfolio/vegan-cola_label_new.jpg")}
                alt="Nowa etykieta Vegan Cola"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default VeganCola;
